"use client";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogProps } from "@radix-ui/react-dialog";
import Link from "next/link";
import { useRouter } from "next-nprogress-bar";
import { Roboto } from "next/font/google";
import { cn } from "@/lib/utils";
import { googleSignIn } from "@/lib/googleUtils";

const robotoFonts = Roboto({
  weight: ["400"],
  subsets: ["latin"],
  display: "swap",
  style: "normal",
});

type Props = DialogProps & {
  headerText?: string;
  callbackUrl?: string;
};

const GoogleAuthModal: React.FC<Props> = ({
  onOpenChange,
  headerText,
  callbackUrl,
  ...props
}) => {
  const router = useRouter();

  return (
    <Dialog
      onOpenChange={
        onOpenChange ? onOpenChange : () => router.replace("/?refetch=true")
      }
      {...props}
    >
      <DialogContent className="py-8">
        <DialogHeader>
          <DialogTitle className="text-center text-3xl">
            {headerText ?? "Sign in to Gondola"}
          </DialogTitle>
          <DialogDescription className="py-1 text-center text-lg">
            A Google account is required to get started.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center justify-center py-1">
          <Button
            variant="outline"
            onClick={() => googleSignIn(callbackUrl)}
            className="font-roboto gap-[10px] border border-[#8e918f] bg-button-light px-3 py-[10px] font-medium tracking-[0.25px]"
          >
            <img
              className="h-5 w-5"
              src="/assets/logos/google.svg"
              alt="google-logo"
            />
            <span className={cn("text-[#e3e3e3]", robotoFonts.className)}>
              Sign in with Google
            </span>
          </Button>
        </div>
        <div className="px-6 pt-2 text-center text-xs text-neutral-300">
          By creating an account you are accepting Gondola&apos;s{" "}
          <Link
            href="/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            href="/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Privacy Policy
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GoogleAuthModal;
